import { Text } from "grommet";
import React from "react";
import { useParams } from "react-router-dom";

const SourceStatement = ({uniqContent}) => {
  let { geoIdUrlParam } = useParams();

  return (
    <Text size="xsmall">
      (Source: {uniqContent} As reported by the CWDG Data Tool,{" "}
      https://wildfirerisk.org/cwdg-tool/{geoIdUrlParam})
    </Text>
  );
};

export default SourceStatement;
