import { useContext } from "react";
import appContext from "../context/appContext";
import { Box, Text } from "grommet";
import StatusCard from "./StatusCard";
import TextForApplication from "./TextForApplication";
import SourceStatement from "./SourceStatement";
import MoreInformation from "./MoreInformation";
import TrackedAnchor from "./TrackedAnchor";

const percentFormatter = new Intl.NumberFormat("en-US", {
  style: "percent",
  maximumFractionDigits: 1,
});

const Scoring_Fire = () => {
  const { cwdgData } = useContext(appContext);

  const rank_wr_state = cwdgData.wildfire.risk_to_homes_state_rank;
  const rank_wr_nation = cwdgData.wildfire.risk_to_homes_national_rank;
  const rank_whp_state = cwdgData.wildfire.whp_state_rank;
  const rank_whp_nation = cwdgData.wildfire.whp_national_rank;

  const geoGetsWildfirePoints =
    rank_wr_state >= 0.7 ||
    rank_wr_nation >= 0.7 ||
    rank_whp_state >= 0.84 ||
    rank_whp_nation >= 0.84;

  const geographyLevel =
    cwdgData.geoMetadata.geo_level === "place"
      ? "communities"
      : cwdgData.geoMetadata.geo_level === "tribal"
      ? "tribal areas and counties"
      : "counties";

  let qualifyingVariable = null;
  if (geoGetsWildfirePoints) {
    if (cwdgData.wildfire.risk_to_homes_national_rank >= 0.7) {
      if (
        !qualifyingVariable ||
        qualifyingVariable.pct < cwdgData.wildfire.risk_to_homes_national_rank
      ) {
        qualifyingVariable = {
          pct: cwdgData.wildfire.risk_to_homes_national_rank,
          variable: "wildfire risk to homes",
          comparisonLevel: "nation",
        };
      }
    }
    if (cwdgData.wildfire.risk_to_homes_state_rank >= 0.7) {
      if (
        !qualifyingVariable ||
        qualifyingVariable.pct < cwdgData.wildfire.risk_to_homes_state_rank
      ) {
        qualifyingVariable = {
          pct: cwdgData.wildfire.risk_to_homes_state_rank,
          variable: "wildfire risk to homes",
          comparisonLevel: "state",
        };
      }
    }
    if (cwdgData.wildfire.whp_national_rank >= 0.84) {
      if (
        !qualifyingVariable ||
        qualifyingVariable.pct < cwdgData.wildfire.whp_national_rank
      ) {
        qualifyingVariable = {
          pct: cwdgData.wildfire.whp_national_rank,
          variable: "wildfire hazard potential",
          comparisonLevel: "nation",
        };
      }
    }
    if (cwdgData.wildfire.whp_state_rank >= 0.84) {
      if (
        !qualifyingVariable ||
        qualifyingVariable.pct < cwdgData.wildfire.whp_state_rank
      ) {
        qualifyingVariable = {
          pct: cwdgData.wildfire.whp_state_rank,
          variable: "wildfire hazard potential",
          comparisonLevel: "state",
        };
      }
    }
  }

  return (
    <StatusCard status={geoGetsWildfirePoints ? "yes" : "maybe"}>
      <Box gap="xsmall">
        <Box gap="xsmall" justify={"start"} direction={"row"} align="baseline">
          <Text size="xsmall" weight={"bolder"}>
            Wildfire Hazard Potential
          </Text>
          <Text size="xsmall">20 points</Text>
        </Box>
        <Box>
          {geoGetsWildfirePoints ? (
            <TextForApplication gaCategory={"Wildfire Scoring"}>
              <Text size="small">
                Yes. {cwdgData.geoMetadata.label}
                {cwdgData.geoMetadata.geo_level === "tribal" ? " " : ", "} meets
                the "wildfire hazard potential" criteria and has a higher{" "}
                {qualifyingVariable.variable} than{" "}
                {percentFormatter.format(qualifyingVariable.pct)} of{" "}
                {geographyLevel} in the {qualifyingVariable.comparisonLevel}.
              </Text>{" "}
              <SourceStatement
                uniqContent={
                  cwdgData.wildfire.wrc_version === "v3"
                    ? "Wildfire Risk to Communities, Version 2. 2024."
                    : "Wildfire Risk to Communities, Version 1. 2020."
                }
              />
            </TextForApplication>
          ) : (
            <Box gap="xsmall">
              <Box>
                <Text size="small">
                  {cwdgData.geoMetadata.label}
                  {cwdgData.geoMetadata.geo_level === "tribal" ? " " : ", "}
                  does not have high or very high wildfire hazard potential
                  according to national datasets. It may still qualify using
                  state or local risk data.
                </Text>
              </Box>
              <Box>
                <Text size="small">Find additional wildfire risk data:</Text>
                <ul>
                  <li>State and local agencies</li>
                  <li>
                    <TrackedAnchor
                      href="https://hazexplorer.com/home"
                      gaCategory={"Eligibility"}
                    >
                      Wildfire Hazard Explorer
                    </TrackedAnchor>
                  </li>
                  <li>
                    <Box>
                      <TrackedAnchor
                        href="https://screeningtool.geoplatform.gov/en/"
                        gaCategory={"Eligibility"}
                      >
                        Climate and Economic Justice Screening Tool
                      </TrackedAnchor>
                      {/* <Text size="xsmall">
                    (Look for “Projected wildfire risk” under the “Climate
                    Change” section)
                  </Text> */}
                    </Box>
                  </li>
                  <li>
                    <TrackedAnchor
                      href="https://hazards.fema.gov/nri/map"
                      gaCategory={"Eligibility"}
                    >
                      National Risk Index
                    </TrackedAnchor>
                  </li>
                  <li>
                    <TrackedAnchor
                      href="https://iseralaska.org/tools/"
                      gaCategory={"Eligibility"}
                    >
                      Alaska Wildfire Hazard Explorer
                    </TrackedAnchor>
                  </li>
                </ul>
              </Box>
            </Box>
          )}
        </Box>
        <MoreInformation gaCategory={"Wildfire Scoring"}>
          The CWDG application allocates 20 points to locations with high or
          very high wildfire hazard potential. "High or very high wildfire
          hazard potential" is defined as having &ge;70th percentile Wildfire
          Risk to Homes, as compared to the state or nation, or having &ge;84th
          percentile Wildfire Hazard Potential as compared to the state or
          nation. This tool will provide the highest qualifying variable from
          the most recent version of the national dataset,{" "}
          <TrackedAnchor
            gaCategory={"Wildfire Scoring"}
            href="https://wildfirerisk.org"
          >
            Wildfire Risk to Communities
          </TrackedAnchor>
          (Version 2). <br />
          <br />
          However, if a location was eligible under the previous Version 1 but
          does not meet the threshold in Version 2, the Version 1 score will be
          shown and can be used for the 2024 CWDG funding cycle.
          <br /> <br />
          Applicants can also choose to use state, local, or other data to
          demonstrate wildfire risk. Find additional wildfire risk data:
          <ul>
            <li>State and local agencies</li>
            <li>
              <TrackedAnchor
                href="https://hazexplorer.com/home"
                gaCategory={"Eligibility"}
              >
                Wildfire Hazard Explorer
              </TrackedAnchor>
            </li>
            <li>
              <Box>
                <TrackedAnchor
                  href="https://screeningtool.geoplatform.gov/en/"
                  gaCategory={"Eligibility"}
                >
                  Climate and Economic Justice Screening Tool
                </TrackedAnchor>
                {/* <Text size="xsmall">
                    (Look for “Projected wildfire risk” under the “Climate
                    Change” section)
                  </Text> */}
              </Box>
            </li>
            <li>
              <TrackedAnchor
                href="https://hazards.fema.gov/nri/map"
                gaCategory={"Eligibility"}
              >
                National Risk Index
              </TrackedAnchor>
            </li>
            <li>
              <TrackedAnchor
                href="https://iseralaska.org/tools/"
                gaCategory={"Eligibility"}
              >
                Alaska Wildfire Hazard Explorer
              </TrackedAnchor>
            </li>
          </ul>
        </MoreInformation>
      </Box>
    </StatusCard>
  );
};
export default Scoring_Fire;
