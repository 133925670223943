import { useContext, useEffect, useState } from "react";
import appContext from "../context/appContext";
import { useLocation } from "react-router-dom";
import ReactGA4 from "react-ga4";

ReactGA4.initialize("G-K1ZBKGMZBN");

const GoogleAnalytics = () => {
  const location = useLocation();
  const [pathname, setPathname] = useState(location);

  const { cwdgData } = useContext(appContext);

  // prevents the location listener from running twice
  useEffect(()=>{
    if (pathname !== location.pathname){
      setPathname(location.pathname);
    } 
  }, [location, pathname]);

  // send a pageview to GA every time a user hits the start screen
  useEffect(() => {
    if (pathname === "/") {
      ReactGA4.send({
        hitType: "pageview",
        page: "cwdg-tool/",
        title: "CWDG start screen",
      });
    }
  }, [pathname]);

  // send a pageview to GA every time a user hits a results page, but only after the place name is available
  useEffect(() => {
    if (
      pathname !== "/" &&
      cwdgData &&
      cwdgData.geoMetadata.id === pathname.split("/")[1] // wait for the location name info
    ) {
      ReactGA4.send({
        hitType: "pageview",
        page: "cwdg-tool" + pathname,
        title: cwdgData.geoMetadata.label,
      });
    }
  }, [pathname, cwdgData]);

  return null;
};

const logGoogleAnalyticsEvent = (category, action, label) => {
  ReactGA4.event({
    category: category,
    action: action,
    label: label,
  });
};

export { logGoogleAnalyticsEvent };
export default GoogleAnalytics;
