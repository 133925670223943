import { Box, Button, Drop, Stack } from "grommet";
import { Copy } from "grommet-icons";
import { useRef, useState } from "react";
import { Like } from "grommet-icons";
import { logGoogleAnalyticsEvent } from "./GoogleAnalytics";

// const StatusCard = ({status, children, props, theme})=>{
//   const success = theme === "dark" ? "light-1" : "#00873D";

const TextForApplication = ({ gaCategory, children }) => {
  const contentRef = useRef(null);
  const buttonRef = useRef(null);
  const [stage, set_stage] = useState(1);
  const [overButton, set_overButton] = useState(false);


  return (
    <Box>
      <Stack anchor="top-right">
        <Box
          direction="column"
          background="light-1"
          border={{ color: "light-4" }}
          round="3px"
          pad={{ vertical: "small", left: "small", right: "medium" }}
          ref={contentRef}
        >
          {children}
        </Box>
        <Box border={{ color: "light-4" }} round="3px">
          <Button
            ref={buttonRef}
            onMouseOver={() => set_overButton(true)}
            onMouseLeave={() => set_overButton(false)}
            onFocus={() => set_overButton(true)}
            onBlur={() => set_overButton(false)}
            disabled={stage === 2}
            onClick={() => {
              navigator.clipboard.writeText(contentRef.current.textContent);
              logGoogleAnalyticsEvent(
                gaCategory,
                "Application text copied",
                ""
              );
              set_stage(2);
              setTimeout(() => {
                set_stage(3);
              }, 2000);
            }}
          >
            <Box pad={"xxsmall"}>
              <Copy size="18px" color={stage > 1 ? "#00873D" : "text"} />
            </Box>
          </Button>
          {buttonRef.current && stage < 3 && (overButton || stage === 2) && (
            <Drop
              plain
              align={{ top: "bottom" }}
              target={buttonRef.current}
              margin={"small"}
              // pad={}
              trapFocus={false}
            >
              <Box
                direction="row"
                pad={"xsmall"}
                round="small"
                background={stage === 1 ? "light-1" : "#00873D"}
                gap="small"
                border
              >
                {stage > 1 && <Like />}
                <Box>
                  {stage > 1
                    ? "Copied to clipboard!"
                    : "Copy text for your application"}
                </Box>
              </Box>
            </Drop>
          )}
        </Box>
      </Stack>
    </Box>
  );
};
export default TextForApplication;
