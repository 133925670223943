import { useViewport } from "@headwaters-economics/web-shared/contexts/Viewport";

import { Box, Text } from "grommet";
import TrackedAnchor from "./TrackedAnchor";

const Footer = () => {
  const { screenSize } = useViewport();

  return (
    <Box
      pad={screenSize === "mobile" ? "medium" : { vertical: "small" }}
      gap="small"
      align="center"
    >
      <Text size="small" textAlign="center">
        &copy;{new Date().getFullYear()}{" "}
        <TrackedAnchor
          color="light-1"
          label="Wildfire Risk to Communities"
          href="https://wildfirerisk.org"
          gaCategory={"App Footer"}
        />{" "}
        · All Rights Reserved
      </Text>
      <Text size="xsmall" textAlign="center">
        USDA is an equal opportunity provider, employer, and lender. This
        website may not contain up-to-date or complete information. Users rely
        on it at their own risk and need to make their own decisions.{" "}
        <TrackedAnchor
          color="light-1"
          label="(Non-Discrimination Statement)"
          href="https://www.usda.gov/non-discrimination-statement"
          gaCategory={"App Footer"}
        />
      </Text>
      <TrackedAnchor
        color="light-1"
        size="xsmall"
        label="USDA FOREST SERVICE"
        href="https://www.fs.usda.gov/"
        gaCategory={"App Footer"}
      />
    </Box>
  );
};

export default Footer;
