import { useContext } from "react";
import appContext from "../context/appContext";

import { Box } from "grommet";

import StatusCard from "./StatusCard";
import TextForApplication from "./TextForApplication";
import MoreInformation from "./MoreInformation";

const Location = () => {
  const { cwdgData } = useContext(appContext);

  return (
    <StatusCard status="location">
      <Box gap="small">
        <Box direction="row" gap="small">
          <Box direction="row" gap="xsmall" fill="horizontal" align="center">
            Latitude
            <Box fill="horizontal">
              <TextForApplication gaCategory={"Latitude"}>
                {cwdgData.geoMetadata.latitude}
              </TextForApplication>
            </Box>
          </Box>
          <Box direction="row" gap="xsmall" fill="horizontal" align="center">
            Longitude
            <Box fill="horizontal">
              <TextForApplication gaCategory={"Longitude"}>
                {cwdgData.geoMetadata.longitude}
              </TextForApplication>
            </Box>
          </Box>
        </Box>
        <MoreInformation gaCategory={"Location"}>
          The CWDG application requires a latitude and longitude. This site
          provides the center point for your{" "}
          {cwdgData.geoMetadata.geo_level === "place"
            ? "community"
            : cwdgData.geoMetadata.geo_level === "county"
            ? "county"
            : "tribal area"}
          . If you would like to provide a more specific location, you can use a
          mapping application to provide custom coordinates.
        </MoreInformation>
      </Box>
    </StatusCard>
  );
};
export default Location;
