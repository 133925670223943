import React from "react";
import { Grommet } from "grommet";
import { deepMerge } from "grommet/utils";
// import AppProvider from "./context/AppProvider";
import HeMainTheme from "@headwaters-economics/web-shared/themes/heMain";
import { ViewportProvider } from "@headwaters-economics/web-shared/contexts/Viewport";
import AppLayout from "./components/AppLayout";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

//for modifying the heMain theme
const cwdgTheme = deepMerge(HeMainTheme, {
  global: {
    spacing: "10px",
    font: {
      family: "Source Sans Pro, sans-serif",
    },
    dataTable: {},
  },
  accordion: {
    border: undefined,
  },
  anchor: {
    color: "neutral-3",
  },
  dataTable: {
    header: {
      pad: "none",
      margin: "none",
      font: {
        weight: "bolder",
        size: "smaller",
      },
    },
    size: "12px",
  },
});

const App = ({ storybookState }) => {
  return (
    <Router basename={process.env.PUBLIC_URL}>
      <ViewportProvider>
        <Grommet plain theme={cwdgTheme}>
          <Routes>
            <Route
              path="/"
              exact
              element={<AppLayout storybookState={storybookState} />}
            ></Route>
            <Route
              path="/:geoIdUrlParam/"
              element={<AppLayout storybookState={storybookState} />}
            ></Route>
          </Routes>
        </Grommet>
      </ViewportProvider>
    </Router>
  );
};

export default App;
