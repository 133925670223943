import { Box } from "grommet"
import { StatusGood as StatusYes, StatusUnknown as StatusMaybe, StatusCritical as StatusNo, CircleInformation, Location  } from "grommet-icons";

const StatusCard = ({status, children, props, theme})=>{
  const success = theme === "dark" ? "light-1" : "#00873D";
  const warning = theme === "dark" ? "light-1" : "#FFAA15";
  const critical = theme === "dark" ? "light-1" : "#A2423D";
  
  return (
    <Box props={props} direction='row' gap="small" pad={{vertical: 'small', horizontal: "small"}} align="top">
      <Box >
        
        {status === 'yes' && <StatusYes size="large" color={success} />}
        {status === 'maybe' && <StatusMaybe size="large" color={warning} />}
        {status === 'no' && <StatusNo size="large" color={critical} />}
        {status === 'na' && <CircleInformation size="large" color="text" />}
        {status === 'location' && <Location size="large" color="text" />}
      </Box>
      <Box fill>
        {children}
      </Box>
    </Box>
  )
}
export default StatusCard;