import { Box, Text, Accordion, AccordionPanel } from "grommet";

import { CircleInformation } from "grommet-icons";
import { useState } from "react";
import { logGoogleAnalyticsEvent } from "./GoogleAnalytics";

const MoreInformation = ({ gaCategory, children }) => {
  const [openedOnce, set_openedOnce] = useState(false);
  return (
    <Accordion
      background="light-2"
      margin="none"
      onActive={() => {
        if (!openedOnce) {
          set_openedOnce(true); // run this event only once
          logGoogleAnalyticsEvent(
            gaCategory,
            "More Info Viewed",
            ""
          );
        }
      }}
    >
      <AccordionPanel
        label={
          <Box
            pad={{ vertical: "xsmall", horizontal: "small" }}
            direction="row"
            gap="xsmall"
            align="center"
          >
            <CircleInformation size="small" />
            <Text margin={"none"} size="small">
              More information
            </Text>
          </Box>
        }
      >
        <Box
          pad={{ horizontal: "small", vertical: "small" }}
          gap="small"
          border={{ side: "top", size: "xsmall", color: "light-4" }}
        >
          <Text size="small">{children}</Text>
        </Box>
      </AccordionPanel>
    </Accordion>
  );
};
export default MoreInformation;
