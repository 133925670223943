import { useViewport } from "@headwaters-economics/web-shared/contexts/Viewport";
import { Box, Heading, Text } from "grommet";
import TrackedAnchor from "./TrackedAnchor";
import ReactPlayer from "react-player";

const AppInfo = () => {
  const { screenSize } = useViewport();

  return (
    <>
      <Box style={{ aspectRatio: "16/9" }} margin={{ top: "medium" }}>
        <ReactPlayer
          controls="true"
          url="https://vimeo.com/848821634"
          width="100%"
          height="100%"
        />
      </Box>
      <Box pad={screenSize === "mobile" ? "small" : { vertical: "small" }}>
        <Heading>About Community Wildfire Defense Grants</Heading>
        <Box margin={{ bottom: "medium" }}>
          <Text size="small">
            The U.S. Forest Service's Community Wildfire Defense Grant (CWDG)
            program is intended to help at-risk communities and Tribes plan for
            and reduce the risk of wildfire. The program, which was authorized
            by the Bipartisan Infrastructure Law, prioritizes at-risk
            communities in areas identified as having high or very high wildfire
            hazard potential, that are low-income, or that have been impacted by
            a severe disaster that increases the risk of wildfire.{" "}
            <TrackedAnchor
              gaCategory={"General App Info"}
              href="https://www.fs.usda.gov/managing-land/fire/grants/cwdg"
            >
              Read more
            </TrackedAnchor>{" "}
            about the CWDG program.{" "}
            <TrackedAnchor
              label="Read more"
              href="https://wildfirerisk.org/about/faq/cwdg-tool/"
              gaCategory={"App Footer"}
            />{" "}
            about the data in this dashboard.
          </Text>
        </Box>

        {/* <Heading>About the data on this website</Heading>
      <Box>
        <Text size="small">
          The data on this website were assembled for the CWDG program by
          Headwaters Economics and the Wildfire Risk to Communities team. This
          information is intended to help communities complete grant
          applications and for reviewers to score applications. Complete
          methodology for this dataset can be 
          <TrackedAnchor
            gaCategory={"General App Info"}
            href="https://wildfirerisk.org/wp-content/uploads/2023/07/CWDG-Dataset-Methods-2023-07.pdf"
          >
            found here
          </TrackedAnchor>
          .
        </Text>
      </Box> */}
        {/* <Heading>Find other funding opportunities</Heading>
      <Box>
        <Text size="medium">
          Explore other funding programs to help communities become better fire
          adapted.
        </Text>
      </Box> */}
      </Box>
    </>
  );
};

export default AppInfo;
