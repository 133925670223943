import React, { useEffect, useReducer } from "react";
import { useNavigate, useParams } from "react-router-dom";
import AppContext from "./appContext";
import useCwdgData from "../hooks/useCwdgData";

const initialState = {
  selectedGeo: null,
  cwdgData: null,
  isEligible: null,
};

const AppProvider = ({ children, storybookState }) => {
  const [state, dispatch] = useReducer(
    appReducer,
    storybookState ? { ...initialState, ...storybookState } : initialState
  );
  const navigate = useNavigate()
  let { geoIdUrlParam } = useParams();

  const { cwdgData, status: cwdgDataFetchStatus } = useCwdgData({
    geoID: geoIdUrlParam && geoIdUrlParam !== 'index.html' ? geoIdUrlParam : null,
  });

  useEffect(() => {
    
    if (cwdgData) {
      const isEligible =
        cwdgData.wildfire.risk_to_homes_state_rank >= 0.4 ||
        cwdgData.wildfire.risk_to_homes_national_rank >= 0.4 ||
        cwdgData.wildfire.whp_state_rank >= 0.67 ||
        cwdgData.wildfire.whp_national_rank >= 0.67
          ? "yes"
          : "maybe";

      if (!state.selectedGeo || cwdgData.geoMetadata.id !== state.selectedGeo.id){
        setSelectedGeo(cwdgData.geoMetadata)
      }
      setIsEligible(isEligible);
    }
    // eslint-disable-next-line
  }, [cwdgData]);
  
  const startOver = () => {
    dispatch({ type: "START_OVER" });
  };

  const setIsEligible = (newValue) => {
    dispatch({ type: "SET_IS_ELIGIBLE", to: newValue });
  };

  const setSelectedGeo = (newGeo) => {
    if (newGeo.id !== geoIdUrlParam){
      navigate("/" + newGeo.id); 
    }
    dispatch({
      type: "SET_SELECTED_GEO",
      to: newGeo,
    });
  };

  return (
    <AppContext.Provider
      value={{
        selectedGeo: state.selectedGeo,

        setSelectedGeo,
        startOver,
        cwdgData,
        cwdgDataFetchStatus,
        isEligible: state.isEligible,
        state,
        dispatch,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

const appReducer = (state, action) => {
  switch (action.type) {
    case "SET_SELECTED_GEO":
      return {
        ...state,
        selectedGeo: action.to,
        isEligible: null,
      };
    case "SET_IS_ELIGIBLE":
      return {
        ...state,
        isEligible: action.to,
      };
    case "START_OVER":
      return {
        ...state,
        selectedGeo: null,
        isEligible: null,
      };

    default:
      throw new Error();
  }
};

export default AppProvider;
