import { Anchor } from "grommet";
import { useState } from "react";
import { logGoogleAnalyticsEvent } from "./GoogleAnalytics";

const TrackedAnchor = ({ gaCategory, children, ...rest }) => {
  const [clickedOnce, set_clickedOnce] = useState(false);
  return (
    <Anchor
      target="_blank"
      {...rest}
      onClick={() => {
        if (!clickedOnce) {
          set_clickedOnce(true);
          logGoogleAnalyticsEvent(gaCategory, "Link Clicked", rest.href);
        }
      }}
    >
      {children}
    </Anchor>
  );
};

export default TrackedAnchor;
