import { useContext } from "react";
import appContext from "../context/appContext";

import { Box, Text } from "grommet";

import StatusCard from "./StatusCard";
import TextForApplication from "./TextForApplication";
import SourceStatement from "./SourceStatement";
import MoreInformation from "./MoreInformation";
import TrackedAnchor from "./TrackedAnchor";

const percentFormatter = new Intl.NumberFormat("en-US", {
  style: "percent",
  maximumFractionDigits: 1,
});

const Eligibility = () => {
  const { cwdgData, isEligible } = useContext(appContext);

  let qualifyingVariable = null;
  if (isEligible === "yes") {
    if (cwdgData.wildfire.risk_to_homes_national_rank >= 0.4) {
      if (
        !qualifyingVariable ||
        qualifyingVariable.pct < cwdgData.wildfire.risk_to_homes_national_rank
      ) {
        qualifyingVariable = {
          pct: cwdgData.wildfire.risk_to_homes_national_rank,
          variable: "Wildfire Risk to Homes",
          comparisonLevel: "nation",
        };
      }
    }
    if (cwdgData.wildfire.risk_to_homes_state_rank >= 0.4) {
      if (
        !qualifyingVariable ||
        qualifyingVariable.pct < cwdgData.wildfire.risk_to_homes_state_rank
      ) {
        qualifyingVariable = {
          pct: cwdgData.wildfire.risk_to_homes_state_rank,
          variable: "Wildfire Risk to Homes",
          comparisonLevel: "state",
        };
      }
    }
    if (cwdgData.wildfire.whp_national_rank >= 0.7) {
      if (
        !qualifyingVariable ||
        qualifyingVariable.pct < cwdgData.wildfire.whp_national_rank
      ) {
        qualifyingVariable = {
          pct: cwdgData.wildfire.whp_national_rank,
          variable: "Wildfire Hazard Potential",
          comparisonLevel: "nation",
        };
      }
    }
    if (cwdgData.wildfire.whp_state_rank >= 0.7) {
      if (
        !qualifyingVariable ||
        qualifyingVariable.pct < cwdgData.wildfire.whp_state_rank
      ) {
        qualifyingVariable = {
          pct: cwdgData.wildfire.whp_state_rank,
          variable: "Wildfire Hazard Potential",
          comparisonLevel: "state",
        };
      }
    }
  }
  return (
    <StatusCard status={isEligible}>
      <Box fill="horizontal" gap="small">
        {isEligible === "maybe" && (
          <Text size="small">
            {cwdgData.geoMetadata.label}
            {cwdgData.geoMetadata.geo_level === "tribal" ? " " : ", "} does not
            have moderate or higher wildfire risk according to national
            datasets. It may still be eligible for CWDG grants using state or
            local risk data. Find additional wildfire risk data:
            <ul>
              <li>State and local agencies</li>
              <li>
                <TrackedAnchor
                  href="https://hazexplorer.com/home"
                  gaCategory={"Eligibility"}
                >
                  Wildfire Hazard Explorer
                </TrackedAnchor>
              </li>
              <li>
                <Box>
                  <TrackedAnchor
                    href="https://screeningtool.geoplatform.gov/en/"
                    gaCategory={"Eligibility"}
                  >
                    Climate and Economic Justice Screening Tool
                  </TrackedAnchor>
                  {/* <Text size="xsmall">
                    (Look for “Projected wildfire risk” under the “Climate
                    Change” section)
                  </Text> */}
                </Box>
              </li>
              <li>
                <TrackedAnchor
                  href="https://hazards.fema.gov/nri/map"
                  gaCategory={"Eligibility"}
                >
                  National Risk Index
                </TrackedAnchor>
              </li>
              <li>
                <TrackedAnchor
                  href="https://iseralaska.org/tools/"
                  gaCategory={"Eligibility"}
                >
                  Alaska Wildfire Hazard Explorer
                </TrackedAnchor>
              </li>
            </ul>
          </Text>
        )}
        {isEligible === "yes" && (
          <TextForApplication gaCategory={"Eligibility"}>
            <Text size="small">
              Yes. {cwdgData.geoMetadata.label}
              {cwdgData.geoMetadata.geo_level === "tribal" ? " " : ", "} is
              considered “at risk.” It has a higher{" "}
              {qualifyingVariable.variable} than{" "}
              {percentFormatter.format(qualifyingVariable.pct)} of{" "}
              {cwdgData.geoMetadata.geo_level === "place"
                ? "communities"
                : cwdgData.geoMetadata.geo_level === "county"
                ? "counties"
                : "tribal areas and counties"}{" "}
              in the {qualifyingVariable.comparisonLevel}.{" "}
            </Text>{" "}
            <SourceStatement
              uniqContent={
                cwdgData.wildfire.wrc_version === "v3"
                  ? "Wildfire Risk to Communities, Version 2. 2024."
                  : "Wildfire Risk to Communities, Version 1. 2020."
              }
            />
          </TextForApplication>
        )}

        <MoreInformation gaCategory={"Eligibility"}>
          To be considered “at risk” and eligible for Community Wildfire Defense
          Grants, a community must have at least moderate wildfire risk. This is
          defined as having &ge;40th percentile Wildfire Risk to Homes, as
          compared to the state or nation, or having &ge;67th percentile
          Wildfire Hazard Potential as compared to the state or nation. This
          tool will provide the highest qualifying variable from the most recent
          version of the national dataset,{" "}
          <TrackedAnchor
            gaCategory={"Eligibility"}
            href="https://wildfirerisk.org"
          >
            Wildfire Risk to Communities
          </TrackedAnchor>
          .<br />
          <br />
          However, if a location was eligible under the previous Version 1 but
          does not meet the threshold in Version 2, the Version 1 score will be
          shown and can be used for the 2024 CWDG funding cycle. 
          <br /> <br />
          Applicants can also choose to use state, local, or other data to
          demonstrate wildfire risk. Find additional wildfire risk data:
          <ul>
            <li>State and local agencies</li>
            <li>
              <TrackedAnchor
                href="https://hazexplorer.com/home"
                gaCategory={"Eligibility"}
              >
                Wildfire Hazard Explorer
              </TrackedAnchor>
            </li>
            <li>
              <Box>
                <TrackedAnchor
                  href="https://screeningtool.geoplatform.gov/en/"
                  gaCategory={"Eligibility"}
                >
                  Climate and Economic Justice Screening Tool
                </TrackedAnchor>
                {/* <Text size="xsmall">
                    (Look for “Projected wildfire risk” under the “Climate
                    Change” section)
                  </Text> */}
              </Box>
            </li>
            <li>
              <TrackedAnchor
                href="https://hazards.fema.gov/nri/map"
                gaCategory={"Eligibility"}
              >
                National Risk Index
              </TrackedAnchor>
            </li>
            <li>
              <TrackedAnchor
                href="https://iseralaska.org/tools/"
                gaCategory={"Eligibility"}
              >
                Alaska Wildfire Hazard Explorer
              </TrackedAnchor>
            </li>
          </ul>
        </MoreInformation>
      </Box>
    </StatusCard>
  );
};
export default Eligibility;
